<template>
  <v-card>
    <v-card-text>
      <p>These are email notifications that will be sent when the form is submitted. This is only for administrative/staff notifications. A confirmation email to the person who submitted it should be set up on the confirmation tab.</p>
      <h3 style="margin-bottom:1em">Email Notifications</h3>
      <v-expansion-panels v-model="notificationsExpanded" style="margin-bottom:1em">
        <notification-block v-for="(notification, index) in notifications" :key="'notification-' + index" v-model="notifications[index]" :formFields="formFields" @remove="removeNotification(index)"></notification-block>
      </v-expansion-panels>
      <v-btn @click="addNotification()">Add a notification on submission</v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="save">
        <v-icon left>fal fa-save</v-icon>
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
export default {
  components: {
    NotificationBlock: () => import('./NotificationBlock')
  },
  props: {
    formId: String,
    revisionId: String
  },
  setup (props, { root }) {
    const userRoles = computed(() => root.$store.state.roles)

    const formFields = ref([])
    watch(() => props.revisionId, () => {
      if (props.revisionId != null && props.revisionId !== '') {
        root.$feathers.service('forms/revision').get(props.revisionId).then(({ sections }) => {
          formFields.value = []
          sections.forEach(({ inputs }) => inputs.forEach(({ label, input, options }) => {
            if (input === 'html-block') return
            formFields.value.push({ value: label, text: label, input, options })
          }))
        })
      }
    })

    const notificationsExpanded = ref(null)
    const notifications = ref([])
    function addNotification () {
      notifications.value.push({
        toEmail: '',
        subject: '',
        fromEmail: '',
        body: '',
        includeData: false,
        isConditional: false,
        conditions: []
      })
      if (notificationsExpanded.value == null) notificationsExpanded.value = 0
      else notificationsExpanded.value++
    }
    function removeNotification (index) {
      notifications.value.splice(index, 1)
    }

    function save () {
      // Only save if the short name is valid
      let patch = {
        notifications: notifications.value
      }
      root.$feathers.service('forms/base').patch(props.formId, patch).then(load).then(() => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Form triggers have been updated' })
      })
    }

    function load (data) {
      if ('notifications' in data) {
        notifications.value = data.notifications
      } else {
        notifications.value = []
      }
    }

    watch(() => props.formId, () => {
      root.$feathers.service('forms/base').get(props.formId).then(load)
    })

    return {
      userRoles,
      formFields,
      notificationsExpanded,
      notifications,
      addNotification,
      removeNotification,
      save,
      load
    }
  }
}
</script>
